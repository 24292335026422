import React, {useState} from 'react';
import {Button, Modal, ModalBody, ModalFooter} from 'reactstrap';
import parse from "html-react-parser";

import FA from "react-fontawesome";

// css import
import './index.css';

const PopupModal = ({resource_path, itemData}) => {

    const [modal, setModal] = useState(true);

    const toggle = () => setModal(!modal);


    return (
        <>
            <Modal isOpen={modal} toggle={toggle}>
                <button type="button" className="close modal-close-btn" onClick={toggle} aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
                <ModalBody>
                    <div className="popup-item" key={itemData.slug}>
                        {
                            itemData.image &&
                            <img className="img-fluid w-100" src={resource_path + itemData.image} alt={itemData.title}/>
                        }
                        {
                            itemData.description &&
                            <div className={`popup-description ${itemData.image ? "mt-2" : ""} text-center`}>
                                {parse(itemData.description)}
                            </div>
                        }
                    </div>
                </ModalBody>
                {/* <ModalFooter className="justify-content-center">
                    <Button color="danger" onClick={toggle}><FA name="close" /> Close</Button>
                </ModalFooter> */}
            </Modal>
            {/* {popup.length ? (
                <Modal isOpen={modal} toggle={toggle}>
                    <button type="button" className="close modal-close-btn" onClick={toggle} aria-label="Close"><span aria-hidden="true">×</span></button>
                    {popup.map(item => {
                        return item.data.length ? item.data.map((itemData, i) => (
                            <ModalBody key={i}>
                                <div className="popup-item" key={itemData.slug}>
                                    {itemData.image && <img className="img-fluid w-100" src={item.resource_path + itemData.image} alt={itemData.title} />}
                                    {itemData.description && <div className="mt-2 text-center">{itemData.description}</div>}
                                </div>
                            </ModalBody>
                        )) : null;
                    })}
                    <ModalFooter className="justify-content-center">
                        <Button color="danger" onClick={toggle}><FA name="close" /> Close</Button>
                    </ModalFooter>
                </Modal>
            ) : null} */}
        </>
    );
};

export default PopupModal;