import React, {Component} from "react";
import {connect} from "react-redux";

//action import
import {getHomePageData} from "actions/frontAction";

//component import
import PopupModal from "components/modals/index";
import RenderBlocks from "utils/RenderBlocks";
import MillsberryClzModal from "../../components/millsberry-college/millsberry-clz-modal";

class Home extends Component {
    render() {
        const hostname = (window.location.hostname.indexOf("www.") && window.location.hostname) || window.location.hostname.replace("www.", "");
        const millsberrySchool = hostname === 'millsberry.edigitalnepal.edu.np';
        const millsberryClz = hostname === 'millsberryclz.edigitalnepal.edu.np';
        const {homePage, popup} = this.props;

        return (
            <>
                <RenderBlocks data={homePage}/>
                {
                    popup.data.length ? popup.data.map((popup, i) => {
                        if (popup.data && popup.data.length) {
                            return popup.data.map((item, j) => {
                                return (
                                    <React.Fragment key={i + "_" + j}>
                                        {
                                            millsberrySchool || millsberryClz ? (
                                                <MillsberryClzModal
                                                    key={i + "_" + j}
                                                    resource_path={popup.resource_path}
                                                    item={item}
                                                />
                                            ) : (
                                                <PopupModal
                                                    key={i + "_" + j}
                                                    resource_path={popup.resource_path}
                                                    itemData={item}
                                                />
                                            )
                                        }
                                    </React.Fragment>
                                );
                            });
                        }
                    }) : null
                }
            </>
        );
    }

    componentDidMount() {
        if (!this.props.homePage.isLoaded) this.props.getHomePageData();
    }
}

function mapStateToProps(state) {
    return {
        homePage: state.homePage,
        popup: state.websiteData.popup,
    };
}

export default connect(mapStateToProps,
    {
        getHomePageData
    }
)(Home);
