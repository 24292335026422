import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import { reducer as reduxFormReducer } from "redux-form";

import {
  websiteDataReducer,
  layoutReducer,
  homePageReducer,
  pageReducer,
  contentReducer,
  photoGalleryPageReducer,
  singleGalleryPageReducer,
  videoGalleryPageReducer,
  contactPageReducer,
  errorReducer,
} from "./frontReducer";


export default history =>
  combineReducers({
    router: connectRouter(history),
    websiteData: websiteDataReducer,
    layout: layoutReducer,
    homePage: homePageReducer,
    page: pageReducer,
    content: contentReducer,
    photoGalleryPage: photoGalleryPageReducer,
    singleGalleryPage: singleGalleryPageReducer,
    videoGalleryPage: videoGalleryPageReducer,
    contactPage: contactPageReducer,
    form: reduxFormReducer,
    error: errorReducer
  });
