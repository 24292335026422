import React from 'react';

// css 
import "./index.css";

// react ui 
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Slide from '@material-ui/core/Slide';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import ClearTwoToneIcon from '@material-ui/icons/ClearTwoTone';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import ShareIcon from '@material-ui/icons/Share';

// component 
import CareerVacancie from "components/career-vacancies/1/index";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
});


// style 
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        "& .MuiAccordionSummary-content": {
            display: "block" + "!important",
            "& .divider": {
                "& span": {
                    fontWeight: "500",
                    fontSize: "13px",
                    color: "#000",
                },
            },
        },
        "& .MuiAccordionActions-root": {
            display: "inline-flex",
        },
        "& .MuiButton-textSizeSmall": {
            height: "36px",
            border: "1px solid #eee",
            minWidth: "141px",
            color: "#3f51b5",
            fontSize: "14px",
        },
        "& .MuiPaper-elevation1": {
            boxShadow: "0 10px 40px rgb(232 231 231 / 50%)",
            padding: "30px 0px",
        },
    },
    "& .MuiDialog-root": {
        zIndex: "9999 !important",
    },
    heading: {
        fontSize: "16px",
        fontWeight: "700",
        flexShrink: 0,
        marginBottom: "10px",
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(12),
        color: theme.palette.text.secondary,
        marginRight: "15px",
        "&:last-child": {
            marginRight: "0",
        },
    },
    outlineButton: {
        outline: "none" + "!important",
        border: "1px solid #eee",
        boxShadow: "inset 0 0 0 0 #006fcf",
        transition: "all .3s ease-out",
        "&:hover": {
            background: "#006fcf",
            color: "#fff",
            boxShadow: "inset 141px 0px 0px 0px #006fcf",
            border: "1px solid #006fcf",
            transform: "scale(1.1)",
        },
    },
    closeButton: {
        outline: "none" + "!important",
    },
    careerVacanciesBg: {
        padding: "50px 0",
        "& .content-holder": {
            "& .text-holder": {
                "& .description": {
                    fontSize: "13px",
                    fontWeight: "normal",
                    lineHeight: "25px",
                    color: "#808080",
                },
                "& .list": {
                    "& .list-title": {
                        fontSize: "16px",
                        fontWeight: "700",
                        marginTop: "30px",
                    },
                    "& ul": {
                        margin: "0",
                        padding: "20px",
                        "& li": {
                            listStyle: "none",
                            marginBottom: "15px",
                            position: "relative",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "25px",
                            fontSize: "13px",
                            "&::before": {
                                content: "''",
                                position: "absolute",
                                left: "0",
                                background: "#006fcf",
                                height: "10px",
                                width: "10px",
                                transform: "rotate(45deg)",
                            },
                            "&:last-child": {
                                marginBottom: "0",
                            },
                        },
                    },
                },
            },
            "& .btn_wrap": {
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
                cursor: "pointer",
                width: "190px",
                height: "36px",
                backgroundColor: "#006fcf2b",
                borderRadius: "5px",
                padding: "0 18px",
                willChange: "transform",
                transition: "all .2s ease-in-out",
                border: "1px solid #eee",
                marginLeft: "-45px",
                "&:hover": {
                    transform: "scale(1.1)",
                    "& span": {
                        transitionDelay: ".25s",
                        transform: "translateX(-280px)",
                    },
                    "& a": {
                        opacity: "1",
                        transform: "scale(1)",
                    },
                },
                "& span": {
                    position: "absolute",
                    zIndex: "99",
                    width: "190px",
                    height: "36px",
                    borderRadius: "5px",
                    fontSize: "14px",
                    textTransform: "uppercase",
                    textAlign: "center",
                    lineHeight: "38px",
                    letterSpacing: "2px",
                    color: "#3f51b5",
                    backgroundColor: "#fff",
                    padding: "0 18px",
                    transition: "all 1.2s ease",
                    fontWeight: "500"
                },
                "& .share-content": {
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    width: "240px",
                    height: "64px",
                    borderRadius: "5px",
                    "& a": {
                        opacity: "0",
                        fontSize: "18px",
                        color: "#006fcf",
                        willChange: "transform",
                        transform: "scale(.1)",
                        transition: "all .3s ease",
                        "&:nth-of-type(1)": {
                            transitionDelay: "1.1",
                        },
                        "&:nth-of-type(2)": {
                            transitionDelay: ".9s",
                        },
                        "&:nth-of-type(3)": {
                            transitionDelay: ".7s",
                        },
                        "&:nth-of-type(4)": {
                            transitionDelay: ".4s",
                        },
                    },
                },
            },
        },

    },
}));

const CareerVacancie3 = ({ career }) => {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <section id="career-vacancies-three" className={`${classes.careerVacanciesBg}`}>
            <div className={`container-fluid ${classes.careerVacanciesMain}`}>
                <div className="content-holder">
                    <div className={classes.root}>
                        <div className="row">
                            <div className="col-lg-6 col-md-12 col-sm-12 mb-4">
                                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1bh-content"
                                        id="panelbh-header"
                                    >
                                        <Typography className={classes.heading}>Lead, Product Growth (Worldwide Remote)</Typography>
                                        <div className="divider d-flex">
                                            <Typography className={classes.secondaryHeading}><span>Type: </span>Full Time</Typography>
                                            <Typography className={classes.secondaryHeading}><span>Min. Experience:</span> 2 Years</Typography>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography component={"span"}>
                                            <div className="text-holder">
                                                <span className="description">We are a diverse, fully-distributed team from around the world, working toward a shared vision to raise the standard of trust online.</span>
                                                <span className="description">Join our team as a remote Lead, Product Growth and help people reclaim their privacy online with DuckDuckGo.</span>
                                                <span className="description">As part of our growing team, you will collaborate with team members at all levels to identify and close major gaps in our products. Examples of projects you might work on include: experimentation with calls-to-action, establishing pathways for adoption of potential new products, and optimization of existing product content.</span>
                                                <div className="list">
                                                    <span className="list-title">What you will do:</span>
                                                    <ul>
                                                        <li>Lead projects from proposal through postmortem, assessing vague problems, proposing high impact solutions, and executing them against a set of success criteria.</li>
                                                        <li>Define and execute the strategy of our optimization work, including setting goals, drafting roadmaps, and driving the actual hypothesis generation and implementation of experiments in a very hands-on fashion.</li>
                                                        <li>Communicate the strategy and progress updates weekly to key stakeholders in the company, your team members and company-wide at our all-hands.</li>
                                                        <li>Deeply understand our most important product flows, marrying anonymous data with intuition to identify and prioritize the most impactful experiments that can improve product adoption.</li>
                                                        <li>Methodically execute experiments alongside cross-functional resources from design, front-end, back-end, mobile and more.</li>
                                                    </ul>
                                                </div>
                                                <div className="list">
                                                    <span className="list-title">What we are looking for:</span>
                                                    <ul>
                                                        <li>Lead projects from proposal through postmortem, assessing vague problems, proposing high impact solutions, and executing them against a set of success criteria.</li>
                                                        <li>Define and execute the strategy of our optimization work, including setting goals, drafting roadmaps, and driving the actual hypothesis generation and implementation of experiments in a very hands-on fashion.</li>
                                                        <li>Communicate the strategy and progress updates weekly to key stakeholders in the company, your team members and company-wide at our all-hands.</li>
                                                        <li>Deeply understand our most important product flows, marrying anonymous data with intuition to identify and prioritize the most impactful experiments that can improve product adoption.</li>
                                                        <li>Methodically execute experiments alongside cross-functional resources from design, front-end, back-end, mobile and more.</li>
                                                    </ul>
                                                </div>
                                                <div className="list">
                                                    <span className="list-title">Other things to know:</span>
                                                    <ul>
                                                        <li>Lead projects from proposal through postmortem, assessing vague problems, proposing high impact solutions, and executing them against a set of success criteria.</li>
                                                        <li>Define and execute the strategy of our optimization work, including setting goals, drafting roadmaps, and driving the actual hypothesis generation and implementation of experiments in a very hands-on fashion.</li>
                                                        <li>Communicate the strategy and progress updates weekly to key stakeholders in the company, your team members and company-wide at our all-hands.</li>
                                                        <li>Deeply understand our most important product flows, marrying anonymous data with intuition to identify and prioritize the most impactful experiments that can improve product adoption.</li>
                                                        <li>Methodically execute experiments alongside cross-functional resources from design, front-end, back-end, mobile and more.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Typography>
                                    </AccordionDetails>
                                    <AccordionActions>
                                        <div className="career-vacancies-modal container">
                                            <div className="career-modal">
                                                <Button variant="outlined" color="primary" onClick={handleClickOpen} className={`${classes.outlineButton}`}>
                                                    Apply Now <DescriptionOutlinedIcon style={{ fontSize: 24, marginLeft: "5px" }} />
                                                </Button>
                                                <Dialog
                                                    open={open}
                                                    fullScreen={fullScreen}
                                                    TransitionComponent={Transition}
                                                    keepMounted
                                                    onClose={handleClose}
                                                    aria-labelledby="alert-dialog-slide-title"
                                                    aria-describedby="alert-dialog-slide-description"
                                                >
                                                    <DialogActions>
                                                        <Button onClick={handleClose} color="primary" autoFocus className={`${classes.closeButton}`}>
                                                            <ClearTwoToneIcon style={{ fontSize: 40 }} />
                                                        </Button>
                                                    </DialogActions>
                                                    <DialogContent style={{ marginTop: "9px" }}>
                                                        <CareerVacancie />
                                                    </DialogContent>
                                                </Dialog>
                                            </div>
                                        </div>
                                        {/* <Button size="small">Share Job <ShareIcon style={{ fontSize: 25, marginLeft: "5px", }} /></Button> */}
                                        <div className="btn_wrap">
                                            <span>Share <ShareIcon style={{ fontSize: 20 }} /></span>
                                            <div className="share-content">
                                                <a href="#"><i className="fa fa-facebook-f"></i></a>
                                                <a href="#"><i className="fa  fa-twitter"></i></a>
                                                <a href="#"><i className="fa fa-instagram"></i></a>
                                                <a href="#"><i className="fa fa-github"></i></a>
                                            </div>
                                        </div>
                                    </AccordionActions>
                                </Accordion>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 mb-4">
                                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2bh-content"
                                        id="panelbh-header"
                                    >
                                        <Typography className={classes.heading}>Lead, Product Growth (Worldwide Remote)</Typography>
                                        <div className="divider d-flex">
                                            <Typography className={classes.secondaryHeading}><span>Type: </span>Full Time</Typography>
                                            <Typography className={classes.secondaryHeading}><span>Min. Experience:</span> 2 Years</Typography>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography component={"span"}>
                                            <div className="text-holder">
                                                <span className="description">We are a diverse, fully-distributed team from around the world, working toward a shared vision to raise the standard of trust online.</span>
                                                <span className="description">Join our team as a remote Lead, Product Growth and help people reclaim their privacy online with DuckDuckGo.</span>
                                                <span className="description">As part of our growing team, you will collaborate with team members at all levels to identify and close major gaps in our products. Examples of projects you might work on include: experimentation with calls-to-action, establishing pathways for adoption of potential new products, and optimization of existing product content.</span>
                                                <div className="list">
                                                    <span className="list-title">What you will do:</span>
                                                    <ul>
                                                        <li>Lead projects from proposal through postmortem, assessing vague problems, proposing high impact solutions, and executing them against a set of success criteria.</li>
                                                        <li>Define and execute the strategy of our optimization work, including setting goals, drafting roadmaps, and driving the actual hypothesis generation and implementation of experiments in a very hands-on fashion.</li>
                                                        <li>Communicate the strategy and progress updates weekly to key stakeholders in the company, your team members and company-wide at our all-hands.</li>
                                                        <li>Deeply understand our most important product flows, marrying anonymous data with intuition to identify and prioritize the most impactful experiments that can improve product adoption.</li>
                                                        <li>Methodically execute experiments alongside cross-functional resources from design, front-end, back-end, mobile and more.</li>
                                                    </ul>
                                                </div>
                                                <div className="list">
                                                    <span className="list-title">What we are looking for:</span>
                                                    <ul>
                                                        <li>Lead projects from proposal through postmortem, assessing vague problems, proposing high impact solutions, and executing them against a set of success criteria.</li>
                                                        <li>Define and execute the strategy of our optimization work, including setting goals, drafting roadmaps, and driving the actual hypothesis generation and implementation of experiments in a very hands-on fashion.</li>
                                                        <li>Communicate the strategy and progress updates weekly to key stakeholders in the company, your team members and company-wide at our all-hands.</li>
                                                        <li>Deeply understand our most important product flows, marrying anonymous data with intuition to identify and prioritize the most impactful experiments that can improve product adoption.</li>
                                                        <li>Methodically execute experiments alongside cross-functional resources from design, front-end, back-end, mobile and more.</li>
                                                    </ul>
                                                </div>
                                                <div className="list">
                                                    <span className="list-title">Other things to know:</span>
                                                    <ul>
                                                        <li>Lead projects from proposal through postmortem, assessing vague problems, proposing high impact solutions, and executing them against a set of success criteria.</li>
                                                        <li>Define and execute the strategy of our optimization work, including setting goals, drafting roadmaps, and driving the actual hypothesis generation and implementation of experiments in a very hands-on fashion.</li>
                                                        <li>Communicate the strategy and progress updates weekly to key stakeholders in the company, your team members and company-wide at our all-hands.</li>
                                                        <li>Deeply understand our most important product flows, marrying anonymous data with intuition to identify and prioritize the most impactful experiments that can improve product adoption.</li>
                                                        <li>Methodically execute experiments alongside cross-functional resources from design, front-end, back-end, mobile and more.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Typography>
                                    </AccordionDetails>
                                    <AccordionActions>
                                        <div className="career-vacancies-modal container">
                                            <div className="career-modal">
                                                <Button variant="outlined" color="primary" onClick={handleClickOpen} className={`${classes.outlineButton}`}>
                                                    Apply Now <DescriptionOutlinedIcon style={{ fontSize: 24, marginLeft: "5px" }} />
                                                </Button>
                                                <Dialog
                                                    open={open}
                                                    fullScreen={fullScreen}
                                                    TransitionComponent={Transition}
                                                    keepMounted
                                                    onClose={handleClose}
                                                    aria-labelledby="alert-dialog-slide-title"
                                                    aria-describedby="alert-dialog-slide-description"
                                                >
                                                    <DialogActions>
                                                        <Button onClick={handleClose} color="primary" autoFocus className={`${classes.closeButton}`}>
                                                            <ClearTwoToneIcon style={{ fontSize: 40 }} />
                                                        </Button>
                                                    </DialogActions>
                                                    <DialogContent style={{ marginTop: "9px" }}>
                                                        <CareerVacancie />
                                                    </DialogContent>
                                                </Dialog>
                                            </div>
                                        </div>
                                        {/* <Button size="small">Share Job <ShareIcon style={{ fontSize: 25, marginLeft: "5px", }} /></Button> */}
                                        <div className="btn_wrap">
                                            <span>Share <ShareIcon style={{ fontSize: 20 }} /></span>
                                            <div className="share-content">
                                                <a href="#"><i className="fa fa-facebook-f"></i></a>
                                                <a href="#"><i className="fa  fa-twitter"></i></a>
                                                <a href="#"><i className="fa fa-instagram"></i></a>
                                                <a href="#"><i className="fa fa-github"></i></a>
                                            </div>
                                        </div>
                                    </AccordionActions>
                                </Accordion>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 mb-4">
                                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel3bh-content"
                                        id="panelbh-header"
                                    >
                                        <Typography className={classes.heading}>Lead, Product Growth (Worldwide Remote)</Typography>
                                        <div className="divider d-flex">
                                            <Typography className={classes.secondaryHeading}><span>Type: </span>Full Time</Typography>
                                            <Typography className={classes.secondaryHeading}><span>Min. Experience:</span> 2 Years</Typography>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography component={"span"}>
                                            <div className="text-holder">
                                                <span className="description">We are a diverse, fully-distributed team from around the world, working toward a shared vision to raise the standard of trust online.</span>
                                                <span className="description">Join our team as a remote Lead, Product Growth and help people reclaim their privacy online with DuckDuckGo.</span>
                                                <span className="description">As part of our growing team, you will collaborate with team members at all levels to identify and close major gaps in our products. Examples of projects you might work on include: experimentation with calls-to-action, establishing pathways for adoption of potential new products, and optimization of existing product content.</span>
                                                <div className="list">
                                                    <span className="list-title">What you will do:</span>
                                                    <ul>
                                                        <li>Lead projects from proposal through postmortem, assessing vague problems, proposing high impact solutions, and executing them against a set of success criteria.</li>
                                                        <li>Define and execute the strategy of our optimization work, including setting goals, drafting roadmaps, and driving the actual hypothesis generation and implementation of experiments in a very hands-on fashion.</li>
                                                        <li>Communicate the strategy and progress updates weekly to key stakeholders in the company, your team members and company-wide at our all-hands.</li>
                                                        <li>Deeply understand our most important product flows, marrying anonymous data with intuition to identify and prioritize the most impactful experiments that can improve product adoption.</li>
                                                        <li>Methodically execute experiments alongside cross-functional resources from design, front-end, back-end, mobile and more.</li>
                                                    </ul>
                                                </div>
                                                <div className="list">
                                                    <span className="list-title">What we are looking for:</span>
                                                    <ul>
                                                        <li>Lead projects from proposal through postmortem, assessing vague problems, proposing high impact solutions, and executing them against a set of success criteria.</li>
                                                        <li>Define and execute the strategy of our optimization work, including setting goals, drafting roadmaps, and driving the actual hypothesis generation and implementation of experiments in a very hands-on fashion.</li>
                                                        <li>Communicate the strategy and progress updates weekly to key stakeholders in the company, your team members and company-wide at our all-hands.</li>
                                                        <li>Deeply understand our most important product flows, marrying anonymous data with intuition to identify and prioritize the most impactful experiments that can improve product adoption.</li>
                                                        <li>Methodically execute experiments alongside cross-functional resources from design, front-end, back-end, mobile and more.</li>
                                                    </ul>
                                                </div>
                                                <div className="list">
                                                    <span className="list-title">Other things to know:</span>
                                                    <ul>
                                                        <li>Lead projects from proposal through postmortem, assessing vague problems, proposing high impact solutions, and executing them against a set of success criteria.</li>
                                                        <li>Define and execute the strategy of our optimization work, including setting goals, drafting roadmaps, and driving the actual hypothesis generation and implementation of experiments in a very hands-on fashion.</li>
                                                        <li>Communicate the strategy and progress updates weekly to key stakeholders in the company, your team members and company-wide at our all-hands.</li>
                                                        <li>Deeply understand our most important product flows, marrying anonymous data with intuition to identify and prioritize the most impactful experiments that can improve product adoption.</li>
                                                        <li>Methodically execute experiments alongside cross-functional resources from design, front-end, back-end, mobile and more.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Typography>
                                    </AccordionDetails>
                                    <AccordionActions>
                                        <div className="career-vacancies-modal container">
                                            <div className="career-modal">
                                                <Button variant="outlined" color="primary" onClick={handleClickOpen} className={`${classes.outlineButton}`}>
                                                    Apply Now <DescriptionOutlinedIcon style={{ fontSize: 24, marginLeft: "5px" }} />
                                                </Button>
                                                <Dialog
                                                    open={open}
                                                    fullScreen={fullScreen}
                                                    TransitionComponent={Transition}
                                                    keepMounted
                                                    onClose={handleClose}
                                                    aria-labelledby="alert-dialog-slide-title"
                                                    aria-describedby="alert-dialog-slide-description"
                                                >
                                                    <DialogActions>
                                                        <Button onClick={handleClose} color="primary" autoFocus className={`${classes.closeButton}`}>
                                                            <ClearTwoToneIcon style={{ fontSize: 40 }} />
                                                        </Button>
                                                    </DialogActions>
                                                    <DialogContent style={{ marginTop: "9px" }}>
                                                        <CareerVacancie />
                                                    </DialogContent>
                                                </Dialog>
                                            </div>
                                        </div>
                                        {/* <Button size="small">Share Job <ShareIcon style={{ fontSize: 25, marginLeft: "5px", }} /></Button> */}
                                        <div className="btn_wrap">
                                            <span>Share <ShareIcon style={{ fontSize: 20 }} /></span>
                                            <div className="share-content">
                                                <a href="#"><i className="fa fa-facebook-f"></i></a>
                                                <a href="#"><i className="fa  fa-twitter"></i></a>
                                                <a href="#"><i className="fa fa-instagram"></i></a>
                                                <a href="#"><i className="fa fa-github"></i></a>
                                            </div>
                                        </div>
                                    </AccordionActions>
                                </Accordion>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 mb-4">
                                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel4bh-content"
                                        id="panelbh-header"
                                    >
                                        <Typography className={classes.heading}>Lead, Product Growth (Worldwide Remote)</Typography>
                                        <div className="divider d-flex">
                                            <Typography className={classes.secondaryHeading}><span>Type: </span>Full Time</Typography>
                                            <Typography className={classes.secondaryHeading}><span>Min. Experience:</span> 2 Years</Typography>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography component={"span"}>
                                            <div className="text-holder">
                                                <span className="description">We are a diverse, fully-distributed team from around the world, working toward a shared vision to raise the standard of trust online.</span>
                                                <span className="description">Join our team as a remote Lead, Product Growth and help people reclaim their privacy online with DuckDuckGo.</span>
                                                <span className="description">As part of our growing team, you will collaborate with team members at all levels to identify and close major gaps in our products. Examples of projects you might work on include: experimentation with calls-to-action, establishing pathways for adoption of potential new products, and optimization of existing product content.</span>
                                                <div className="list">
                                                    <span className="list-title">What you will do:</span>
                                                    <ul>
                                                        <li>Lead projects from proposal through postmortem, assessing vague problems, proposing high impact solutions, and executing them against a set of success criteria.</li>
                                                        <li>Define and execute the strategy of our optimization work, including setting goals, drafting roadmaps, and driving the actual hypothesis generation and implementation of experiments in a very hands-on fashion.</li>
                                                        <li>Communicate the strategy and progress updates weekly to key stakeholders in the company, your team members and company-wide at our all-hands.</li>
                                                        <li>Deeply understand our most important product flows, marrying anonymous data with intuition to identify and prioritize the most impactful experiments that can improve product adoption.</li>
                                                        <li>Methodically execute experiments alongside cross-functional resources from design, front-end, back-end, mobile and more.</li>
                                                    </ul>
                                                </div>
                                                <div className="list">
                                                    <span className="list-title">What we are looking for:</span>
                                                    <ul>
                                                        <li>Lead projects from proposal through postmortem, assessing vague problems, proposing high impact solutions, and executing them against a set of success criteria.</li>
                                                        <li>Define and execute the strategy of our optimization work, including setting goals, drafting roadmaps, and driving the actual hypothesis generation and implementation of experiments in a very hands-on fashion.</li>
                                                        <li>Communicate the strategy and progress updates weekly to key stakeholders in the company, your team members and company-wide at our all-hands.</li>
                                                        <li>Deeply understand our most important product flows, marrying anonymous data with intuition to identify and prioritize the most impactful experiments that can improve product adoption.</li>
                                                        <li>Methodically execute experiments alongside cross-functional resources from design, front-end, back-end, mobile and more.</li>
                                                    </ul>
                                                </div>
                                                <div className="list">
                                                    <span className="list-title">Other things to know:</span>
                                                    <ul>
                                                        <li>Lead projects from proposal through postmortem, assessing vague problems, proposing high impact solutions, and executing them against a set of success criteria.</li>
                                                        <li>Define and execute the strategy of our optimization work, including setting goals, drafting roadmaps, and driving the actual hypothesis generation and implementation of experiments in a very hands-on fashion.</li>
                                                        <li>Communicate the strategy and progress updates weekly to key stakeholders in the company, your team members and company-wide at our all-hands.</li>
                                                        <li>Deeply understand our most important product flows, marrying anonymous data with intuition to identify and prioritize the most impactful experiments that can improve product adoption.</li>
                                                        <li>Methodically execute experiments alongside cross-functional resources from design, front-end, back-end, mobile and more.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Typography>
                                    </AccordionDetails>
                                    <AccordionActions>
                                        <div className="career-vacancies-modal container">
                                            <div className="career-modal">
                                                <Button variant="outlined" color="primary" onClick={handleClickOpen} className={`${classes.outlineButton}`}>
                                                    Apply Now <DescriptionOutlinedIcon style={{ fontSize: 24, marginLeft: "5px" }} />
                                                </Button>
                                                <Dialog
                                                    open={open}
                                                    fullScreen={fullScreen}
                                                    TransitionComponent={Transition}
                                                    keepMounted
                                                    onClose={handleClose}
                                                    aria-labelledby="alert-dialog-slide-title"
                                                    aria-describedby="alert-dialog-slide-description"
                                                >
                                                    <DialogActions>
                                                        <Button onClick={handleClose} color="primary" autoFocus className={`${classes.closeButton}`}>
                                                            <ClearTwoToneIcon style={{ fontSize: 40 }} />
                                                        </Button>
                                                    </DialogActions>
                                                    <DialogContent style={{ marginTop: "9px" }}>
                                                        <CareerVacancie />
                                                    </DialogContent>
                                                </Dialog>
                                            </div>
                                        </div>
                                        {/* <Button size="small">Share Job <ShareIcon style={{ fontSize: 25, marginLeft: "5px", }} /></Button> */}
                                        <div className="btn_wrap">
                                            <span>Share <ShareIcon style={{ fontSize: 20 }} /></span>
                                            <div className="share-content">
                                                <a href="#"><i className="fa fa-facebook-f"></i></a>
                                                <a href="#"><i className="fa  fa-twitter"></i></a>
                                                <a href="#"><i className="fa fa-instagram"></i></a>
                                                <a href="#"><i className="fa fa-github"></i></a>
                                            </div>
                                        </div>
                                    </AccordionActions>
                                </Accordion>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 mb-4">
                                <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel5bh-content"
                                        id="panelbh-header"
                                    >
                                        <Typography className={classes.heading}>Lead, Product Growth (Worldwide Remote)</Typography>
                                        <div className="divider d-flex">
                                            <Typography className={classes.secondaryHeading}><span>Type: </span>Full Time</Typography>
                                            <Typography className={classes.secondaryHeading}><span>Min. Experience:</span> 2 Years</Typography>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography component={"span"}>
                                            <div className="text-holder">
                                                <span className="description">We are a diverse, fully-distributed team from around the world, working toward a shared vision to raise the standard of trust online.</span>
                                                <span className="description">Join our team as a remote Lead, Product Growth and help people reclaim their privacy online with DuckDuckGo.</span>
                                                <span className="description">As part of our growing team, you will collaborate with team members at all levels to identify and close major gaps in our products. Examples of projects you might work on include: experimentation with calls-to-action, establishing pathways for adoption of potential new products, and optimization of existing product content.</span>
                                                <div className="list">
                                                    <span className="list-title">What you will do:</span>
                                                    <ul>
                                                        <li>Lead projects from proposal through postmortem, assessing vague problems, proposing high impact solutions, and executing them against a set of success criteria.</li>
                                                        <li>Define and execute the strategy of our optimization work, including setting goals, drafting roadmaps, and driving the actual hypothesis generation and implementation of experiments in a very hands-on fashion.</li>
                                                        <li>Communicate the strategy and progress updates weekly to key stakeholders in the company, your team members and company-wide at our all-hands.</li>
                                                        <li>Deeply understand our most important product flows, marrying anonymous data with intuition to identify and prioritize the most impactful experiments that can improve product adoption.</li>
                                                        <li>Methodically execute experiments alongside cross-functional resources from design, front-end, back-end, mobile and more.</li>
                                                    </ul>
                                                </div>
                                                <div className="list">
                                                    <span className="list-title">What we are looking for:</span>
                                                    <ul>
                                                        <li>Lead projects from proposal through postmortem, assessing vague problems, proposing high impact solutions, and executing them against a set of success criteria.</li>
                                                        <li>Define and execute the strategy of our optimization work, including setting goals, drafting roadmaps, and driving the actual hypothesis generation and implementation of experiments in a very hands-on fashion.</li>
                                                        <li>Communicate the strategy and progress updates weekly to key stakeholders in the company, your team members and company-wide at our all-hands.</li>
                                                        <li>Deeply understand our most important product flows, marrying anonymous data with intuition to identify and prioritize the most impactful experiments that can improve product adoption.</li>
                                                        <li>Methodically execute experiments alongside cross-functional resources from design, front-end, back-end, mobile and more.</li>
                                                    </ul>
                                                </div>
                                                <div className="list">
                                                    <span className="list-title">Other things to know:</span>
                                                    <ul>
                                                        <li>Lead projects from proposal through postmortem, assessing vague problems, proposing high impact solutions, and executing them against a set of success criteria.</li>
                                                        <li>Define and execute the strategy of our optimization work, including setting goals, drafting roadmaps, and driving the actual hypothesis generation and implementation of experiments in a very hands-on fashion.</li>
                                                        <li>Communicate the strategy and progress updates weekly to key stakeholders in the company, your team members and company-wide at our all-hands.</li>
                                                        <li>Deeply understand our most important product flows, marrying anonymous data with intuition to identify and prioritize the most impactful experiments that can improve product adoption.</li>
                                                        <li>Methodically execute experiments alongside cross-functional resources from design, front-end, back-end, mobile and more.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Typography>
                                    </AccordionDetails>
                                    <AccordionActions>
                                        <div className="career-vacancies-modal container">
                                            <div className="career-modal">
                                                <Button variant="outlined" color="primary" onClick={handleClickOpen} className={`${classes.outlineButton}`}>
                                                    Apply Now <DescriptionOutlinedIcon style={{ fontSize: 24, marginLeft: "5px" }} />
                                                </Button>
                                                <Dialog
                                                    open={open}
                                                    fullScreen={fullScreen}
                                                    TransitionComponent={Transition}
                                                    keepMounted
                                                    onClose={handleClose}
                                                    aria-labelledby="alert-dialog-slide-title"
                                                    aria-describedby="alert-dialog-slide-description"
                                                >
                                                    <DialogActions>
                                                        <Button onClick={handleClose} color="primary" autoFocus className={`${classes.closeButton}`}>
                                                            <ClearTwoToneIcon style={{ fontSize: 40 }} />
                                                        </Button>
                                                    </DialogActions>
                                                    <DialogContent style={{ marginTop: "9px" }}>
                                                        <CareerVacancie />
                                                    </DialogContent>
                                                </Dialog>
                                            </div>
                                        </div>
                                        {/* <Button size="small">Share Job <ShareIcon style={{ fontSize: 25, marginLeft: "5px", }} /></Button> */}
                                        <div className="btn_wrap">
                                            <span>Share <ShareIcon style={{ fontSize: 20 }} /></span>
                                            <div className="share-content">
                                                <a href="#"><i className="fa fa-facebook-f"></i></a>
                                                <a href="#"><i className="fa  fa-twitter"></i></a>
                                                <a href="#"><i className="fa fa-instagram"></i></a>
                                                <a href="#"><i className="fa fa-github"></i></a>
                                            </div>
                                        </div>
                                    </AccordionActions>
                                </Accordion>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 mb-4">
                                <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel6bh-content"
                                        id="panelbh-header"
                                    >
                                        <Typography className={classes.heading}>Lead, Product Growth (Worldwide Remote)</Typography>
                                        <div className="divider d-flex">
                                            <Typography className={classes.secondaryHeading}><span>Type: </span>Full Time</Typography>
                                            <Typography className={classes.secondaryHeading}><span>Min. Experience:</span> 2 Years</Typography>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography component={"span"}>
                                            <div className="text-holder">
                                                <span className="description">We are a diverse, fully-distributed team from around the world, working toward a shared vision to raise the standard of trust online.</span>
                                                <span className="description">Join our team as a remote Lead, Product Growth and help people reclaim their privacy online with DuckDuckGo.</span>
                                                <span className="description">As part of our growing team, you will collaborate with team members at all levels to identify and close major gaps in our products. Examples of projects you might work on include: experimentation with calls-to-action, establishing pathways for adoption of potential new products, and optimization of existing product content.</span>
                                                <div className="list">
                                                    <span className="list-title">What you will do:</span>
                                                    <ul>
                                                        <li>Lead projects from proposal through postmortem, assessing vague problems, proposing high impact solutions, and executing them against a set of success criteria.</li>
                                                        <li>Define and execute the strategy of our optimization work, including setting goals, drafting roadmaps, and driving the actual hypothesis generation and implementation of experiments in a very hands-on fashion.</li>
                                                        <li>Communicate the strategy and progress updates weekly to key stakeholders in the company, your team members and company-wide at our all-hands.</li>
                                                        <li>Deeply understand our most important product flows, marrying anonymous data with intuition to identify and prioritize the most impactful experiments that can improve product adoption.</li>
                                                        <li>Methodically execute experiments alongside cross-functional resources from design, front-end, back-end, mobile and more.</li>
                                                    </ul>
                                                </div>
                                                <div className="list">
                                                    <span className="list-title">What we are looking for:</span>
                                                    <ul>
                                                        <li>Lead projects from proposal through postmortem, assessing vague problems, proposing high impact solutions, and executing them against a set of success criteria.</li>
                                                        <li>Define and execute the strategy of our optimization work, including setting goals, drafting roadmaps, and driving the actual hypothesis generation and implementation of experiments in a very hands-on fashion.</li>
                                                        <li>Communicate the strategy and progress updates weekly to key stakeholders in the company, your team members and company-wide at our all-hands.</li>
                                                        <li>Deeply understand our most important product flows, marrying anonymous data with intuition to identify and prioritize the most impactful experiments that can improve product adoption.</li>
                                                        <li>Methodically execute experiments alongside cross-functional resources from design, front-end, back-end, mobile and more.</li>
                                                    </ul>
                                                </div>
                                                <div className="list">
                                                    <span className="list-title">Other things to know:</span>
                                                    <ul>
                                                        <li>Lead projects from proposal through postmortem, assessing vague problems, proposing high impact solutions, and executing them against a set of success criteria.</li>
                                                        <li>Define and execute the strategy of our optimization work, including setting goals, drafting roadmaps, and driving the actual hypothesis generation and implementation of experiments in a very hands-on fashion.</li>
                                                        <li>Communicate the strategy and progress updates weekly to key stakeholders in the company, your team members and company-wide at our all-hands.</li>
                                                        <li>Deeply understand our most important product flows, marrying anonymous data with intuition to identify and prioritize the most impactful experiments that can improve product adoption.</li>
                                                        <li>Methodically execute experiments alongside cross-functional resources from design, front-end, back-end, mobile and more.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Typography>
                                    </AccordionDetails>
                                    <AccordionActions>
                                        <div className="career-vacancies-modal container">
                                            <div className="career-modal">
                                                <Button variant="outlined" color="primary" onClick={handleClickOpen} className={`${classes.outlineButton}`}>
                                                    Apply Now <DescriptionOutlinedIcon style={{ fontSize: 24, marginLeft: "5px" }} />
                                                </Button>
                                                <Dialog
                                                    open={open}
                                                    fullScreen={fullScreen}
                                                    TransitionComponent={Transition}
                                                    keepMounted
                                                    onClose={handleClose}
                                                    aria-labelledby="alert-dialog-slide-title"
                                                    aria-describedby="alert-dialog-slide-description"
                                                >
                                                    <DialogActions>
                                                        <Button onClick={handleClose} color="primary" autoFocus className={`${classes.closeButton}`}>
                                                            <ClearTwoToneIcon style={{ fontSize: 40 }} />
                                                        </Button>
                                                    </DialogActions>
                                                    <DialogContent style={{ marginTop: "9px" }}>
                                                        <CareerVacancie />
                                                    </DialogContent>
                                                </Dialog>
                                            </div>
                                        </div>
                                        {/* <Button size="small">Share Job <ShareIcon style={{ fontSize: 25, marginLeft: "5px", }} /></Button> */}
                                        <div className="btn_wrap">
                                            <span>Share <ShareIcon style={{ fontSize: 20 }} /></span>
                                            <div className="share-content">
                                                <a href="#"><i className="fa fa-facebook-f"></i></a>
                                                <a href="#"><i className="fa  fa-twitter"></i></a>
                                                <a href="#"><i className="fa fa-instagram"></i></a>
                                                <a href="#"><i className="fa fa-github"></i></a>
                                            </div>
                                        </div>
                                    </AccordionActions>
                                </Accordion>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 mb-4">
                                <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel7bh-content"
                                        id="panelbh-header"
                                    >
                                        <Typography className={classes.heading}>Lead, Product Growth (Worldwide Remote)</Typography>
                                        <div className="divider d-flex">
                                            <Typography className={classes.secondaryHeading}><span>Type: </span>Full Time</Typography>
                                            <Typography className={classes.secondaryHeading}><span>Min. Experience:</span> 2 Years</Typography>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography component={"span"}>
                                            <div className="text-holder">
                                                <span className="description">We are a diverse, fully-distributed team from around the world, working toward a shared vision to raise the standard of trust online.</span>
                                                <span className="description">Join our team as a remote Lead, Product Growth and help people reclaim their privacy online with DuckDuckGo.</span>
                                                <span className="description">As part of our growing team, you will collaborate with team members at all levels to identify and close major gaps in our products. Examples of projects you might work on include: experimentation with calls-to-action, establishing pathways for adoption of potential new products, and optimization of existing product content.</span>
                                                <div className="list">
                                                    <span className="list-title">What you will do:</span>
                                                    <ul>
                                                        <li>Lead projects from proposal through postmortem, assessing vague problems, proposing high impact solutions, and executing them against a set of success criteria.</li>
                                                        <li>Define and execute the strategy of our optimization work, including setting goals, drafting roadmaps, and driving the actual hypothesis generation and implementation of experiments in a very hands-on fashion.</li>
                                                        <li>Communicate the strategy and progress updates weekly to key stakeholders in the company, your team members and company-wide at our all-hands.</li>
                                                        <li>Deeply understand our most important product flows, marrying anonymous data with intuition to identify and prioritize the most impactful experiments that can improve product adoption.</li>
                                                        <li>Methodically execute experiments alongside cross-functional resources from design, front-end, back-end, mobile and more.</li>
                                                    </ul>
                                                </div>
                                                <div className="list">
                                                    <span className="list-title">What we are looking for:</span>
                                                    <ul>
                                                        <li>Lead projects from proposal through postmortem, assessing vague problems, proposing high impact solutions, and executing them against a set of success criteria.</li>
                                                        <li>Define and execute the strategy of our optimization work, including setting goals, drafting roadmaps, and driving the actual hypothesis generation and implementation of experiments in a very hands-on fashion.</li>
                                                        <li>Communicate the strategy and progress updates weekly to key stakeholders in the company, your team members and company-wide at our all-hands.</li>
                                                        <li>Deeply understand our most important product flows, marrying anonymous data with intuition to identify and prioritize the most impactful experiments that can improve product adoption.</li>
                                                        <li>Methodically execute experiments alongside cross-functional resources from design, front-end, back-end, mobile and more.</li>
                                                    </ul>
                                                </div>
                                                <div className="list">
                                                    <span className="list-title">Other things to know:</span>
                                                    <ul>
                                                        <li>Lead projects from proposal through postmortem, assessing vague problems, proposing high impact solutions, and executing them against a set of success criteria.</li>
                                                        <li>Define and execute the strategy of our optimization work, including setting goals, drafting roadmaps, and driving the actual hypothesis generation and implementation of experiments in a very hands-on fashion.</li>
                                                        <li>Communicate the strategy and progress updates weekly to key stakeholders in the company, your team members and company-wide at our all-hands.</li>
                                                        <li>Deeply understand our most important product flows, marrying anonymous data with intuition to identify and prioritize the most impactful experiments that can improve product adoption.</li>
                                                        <li>Methodically execute experiments alongside cross-functional resources from design, front-end, back-end, mobile and more.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Typography>
                                    </AccordionDetails>
                                    <AccordionActions>
                                        <div className="career-vacancies-modal container">
                                            <div className="career-modal">
                                                <Button variant="outlined" color="primary" onClick={handleClickOpen} className={`${classes.outlineButton}`}>
                                                    Apply Now <DescriptionOutlinedIcon style={{ fontSize: 24, marginLeft: "5px" }} />
                                                </Button>
                                                <Dialog
                                                    open={open}
                                                    fullScreen={fullScreen}
                                                    TransitionComponent={Transition}
                                                    keepMounted
                                                    onClose={handleClose}
                                                    aria-labelledby="alert-dialog-slide-title"
                                                    aria-describedby="alert-dialog-slide-description"
                                                >
                                                    <DialogActions>
                                                        <Button onClick={handleClose} color="primary" autoFocus className={`${classes.closeButton}`}>
                                                            <ClearTwoToneIcon style={{ fontSize: 40 }} />
                                                        </Button>
                                                    </DialogActions>
                                                    <DialogContent style={{ marginTop: "9px" }}>
                                                        <CareerVacancie />
                                                    </DialogContent>
                                                </Dialog>
                                            </div>
                                        </div>
                                        {/* <Button size="small">Share Job <ShareIcon style={{ fontSize: 25, marginLeft: "5px", }} /></Button> */}
                                        <div className="btn_wrap">
                                            <span>Share <ShareIcon style={{ fontSize: 20 }} /></span>
                                            <div className="share-content">
                                                <a href="#"><i className="fa fa-facebook-f"></i></a>
                                                <a href="#"><i className="fa  fa-twitter"></i></a>
                                                <a href="#"><i className="fa fa-instagram"></i></a>
                                                <a href="#"><i className="fa fa-github"></i></a>
                                            </div>
                                        </div>
                                    </AccordionActions>
                                </Accordion>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 mb-4">
                                <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel8bh-content"
                                        id="panelbh-header"
                                    >
                                        <Typography className={classes.heading}>Lead, Product Growth (Worldwide Remote)</Typography>
                                        <div className="divider d-flex">
                                            <Typography className={classes.secondaryHeading}><span>Type: </span>Full Time</Typography>
                                            <Typography className={classes.secondaryHeading}><span>Min. Experience:</span> 2 Years</Typography>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography component={"span"}>
                                            <div className="text-holder">
                                                <span className="description">We are a diverse, fully-distributed team from around the world, working toward a shared vision to raise the standard of trust online.</span>
                                                <span className="description">Join our team as a remote Lead, Product Growth and help people reclaim their privacy online with DuckDuckGo.</span>
                                                <span className="description">As part of our growing team, you will collaborate with team members at all levels to identify and close major gaps in our products. Examples of projects you might work on include: experimentation with calls-to-action, establishing pathways for adoption of potential new products, and optimization of existing product content.</span>
                                                <div className="list">
                                                    <span className="list-title">What you will do:</span>
                                                    <ul>
                                                        <li>Lead projects from proposal through postmortem, assessing vague problems, proposing high impact solutions, and executing them against a set of success criteria.</li>
                                                        <li>Define and execute the strategy of our optimization work, including setting goals, drafting roadmaps, and driving the actual hypothesis generation and implementation of experiments in a very hands-on fashion.</li>
                                                        <li>Communicate the strategy and progress updates weekly to key stakeholders in the company, your team members and company-wide at our all-hands.</li>
                                                        <li>Deeply understand our most important product flows, marrying anonymous data with intuition to identify and prioritize the most impactful experiments that can improve product adoption.</li>
                                                        <li>Methodically execute experiments alongside cross-functional resources from design, front-end, back-end, mobile and more.</li>
                                                    </ul>
                                                </div>
                                                <div className="list">
                                                    <span className="list-title">What we are looking for:</span>
                                                    <ul>
                                                        <li>Lead projects from proposal through postmortem, assessing vague problems, proposing high impact solutions, and executing them against a set of success criteria.</li>
                                                        <li>Define and execute the strategy of our optimization work, including setting goals, drafting roadmaps, and driving the actual hypothesis generation and implementation of experiments in a very hands-on fashion.</li>
                                                        <li>Communicate the strategy and progress updates weekly to key stakeholders in the company, your team members and company-wide at our all-hands.</li>
                                                        <li>Deeply understand our most important product flows, marrying anonymous data with intuition to identify and prioritize the most impactful experiments that can improve product adoption.</li>
                                                        <li>Methodically execute experiments alongside cross-functional resources from design, front-end, back-end, mobile and more.</li>
                                                    </ul>
                                                </div>
                                                <div className="list">
                                                    <span className="list-title">Other things to know:</span>
                                                    <ul>
                                                        <li>Lead projects from proposal through postmortem, assessing vague problems, proposing high impact solutions, and executing them against a set of success criteria.</li>
                                                        <li>Define and execute the strategy of our optimization work, including setting goals, drafting roadmaps, and driving the actual hypothesis generation and implementation of experiments in a very hands-on fashion.</li>
                                                        <li>Communicate the strategy and progress updates weekly to key stakeholders in the company, your team members and company-wide at our all-hands.</li>
                                                        <li>Deeply understand our most important product flows, marrying anonymous data with intuition to identify and prioritize the most impactful experiments that can improve product adoption.</li>
                                                        <li>Methodically execute experiments alongside cross-functional resources from design, front-end, back-end, mobile and more.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Typography>
                                    </AccordionDetails>
                                    <AccordionActions>
                                        <div className="career-vacancies-modal container">
                                            <div className="career-modal">
                                                <Button variant="outlined" color="primary" onClick={handleClickOpen} className={`${classes.outlineButton}`}>
                                                    Apply Now <DescriptionOutlinedIcon style={{ fontSize: 24, marginLeft: "5px" }} />
                                                </Button>
                                                <Dialog
                                                    open={open}
                                                    fullScreen={fullScreen}
                                                    TransitionComponent={Transition}
                                                    keepMounted
                                                    onClose={handleClose}
                                                    aria-labelledby="alert-dialog-slide-title"
                                                    aria-describedby="alert-dialog-slide-description"
                                                >
                                                    <DialogActions>
                                                        <Button onClick={handleClose} color="primary" autoFocus className={`${classes.closeButton}`}>
                                                            <ClearTwoToneIcon style={{ fontSize: 40 }} />
                                                        </Button>
                                                    </DialogActions>
                                                    <DialogContent style={{ marginTop: "9px" }}>
                                                        <CareerVacancie />
                                                    </DialogContent>
                                                </Dialog>
                                            </div>
                                        </div>
                                        {/* <Button size="small">Share Job <ShareIcon style={{ fontSize: 25, marginLeft: "5px", }} /></Button> */}
                                        <div className="btn_wrap">
                                            <span>Share <ShareIcon style={{ fontSize: 20 }} /></span>
                                            <div className="share-content">
                                                <a href="#"><i className="fa fa-facebook-f"></i></a>
                                                <a href="#"><i className="fa  fa-twitter"></i></a>
                                                <a href="#"><i className="fa fa-instagram"></i></a>
                                                <a href="#"><i className="fa fa-github"></i></a>
                                            </div>
                                        </div>
                                    </AccordionActions>
                                </Accordion>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 mb-4">
                                <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel9bh-content"
                                        id="panelbh-header"
                                    >
                                        <Typography className={classes.heading}>Lead, Product Growth (Worldwide Remote)</Typography>
                                        <div className="divider d-flex">
                                            <Typography className={classes.secondaryHeading}><span>Type: </span>Full Time</Typography>
                                            <Typography className={classes.secondaryHeading}><span>Min. Experience:</span> 2 Years</Typography>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography component={"span"}>
                                            <div className="text-holder">
                                                <span className="description">We are a diverse, fully-distributed team from around the world, working toward a shared vision to raise the standard of trust online.</span>
                                                <span className="description">Join our team as a remote Lead, Product Growth and help people reclaim their privacy online with DuckDuckGo.</span>
                                                <span className="description">As part of our growing team, you will collaborate with team members at all levels to identify and close major gaps in our products. Examples of projects you might work on include: experimentation with calls-to-action, establishing pathways for adoption of potential new products, and optimization of existing product content.</span>
                                                <div className="list">
                                                    <span className="list-title">What you will do:</span>
                                                    <ul>
                                                        <li>Lead projects from proposal through postmortem, assessing vague problems, proposing high impact solutions, and executing them against a set of success criteria.</li>
                                                        <li>Define and execute the strategy of our optimization work, including setting goals, drafting roadmaps, and driving the actual hypothesis generation and implementation of experiments in a very hands-on fashion.</li>
                                                        <li>Communicate the strategy and progress updates weekly to key stakeholders in the company, your team members and company-wide at our all-hands.</li>
                                                        <li>Deeply understand our most important product flows, marrying anonymous data with intuition to identify and prioritize the most impactful experiments that can improve product adoption.</li>
                                                        <li>Methodically execute experiments alongside cross-functional resources from design, front-end, back-end, mobile and more.</li>
                                                    </ul>
                                                </div>
                                                <div className="list">
                                                    <span className="list-title">What we are looking for:</span>
                                                    <ul>
                                                        <li>Lead projects from proposal through postmortem, assessing vague problems, proposing high impact solutions, and executing them against a set of success criteria.</li>
                                                        <li>Define and execute the strategy of our optimization work, including setting goals, drafting roadmaps, and driving the actual hypothesis generation and implementation of experiments in a very hands-on fashion.</li>
                                                        <li>Communicate the strategy and progress updates weekly to key stakeholders in the company, your team members and company-wide at our all-hands.</li>
                                                        <li>Deeply understand our most important product flows, marrying anonymous data with intuition to identify and prioritize the most impactful experiments that can improve product adoption.</li>
                                                        <li>Methodically execute experiments alongside cross-functional resources from design, front-end, back-end, mobile and more.</li>
                                                    </ul>
                                                </div>
                                                <div className="list">
                                                    <span className="list-title">Other things to know:</span>
                                                    <ul>
                                                        <li>Lead projects from proposal through postmortem, assessing vague problems, proposing high impact solutions, and executing them against a set of success criteria.</li>
                                                        <li>Define and execute the strategy of our optimization work, including setting goals, drafting roadmaps, and driving the actual hypothesis generation and implementation of experiments in a very hands-on fashion.</li>
                                                        <li>Communicate the strategy and progress updates weekly to key stakeholders in the company, your team members and company-wide at our all-hands.</li>
                                                        <li>Deeply understand our most important product flows, marrying anonymous data with intuition to identify and prioritize the most impactful experiments that can improve product adoption.</li>
                                                        <li>Methodically execute experiments alongside cross-functional resources from design, front-end, back-end, mobile and more.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Typography>
                                    </AccordionDetails>
                                    <AccordionActions>
                                        <div className="career-vacancies-modal container">
                                            <div className="career-modal">
                                                <Button variant="outlined" color="primary" onClick={handleClickOpen} className={`${classes.outlineButton}`}>
                                                    Apply Now <DescriptionOutlinedIcon style={{ fontSize: 24, marginLeft: "5px" }} />
                                                </Button>
                                                <Dialog
                                                    open={open}
                                                    fullScreen={fullScreen}
                                                    TransitionComponent={Transition}
                                                    keepMounted
                                                    onClose={handleClose}
                                                    aria-labelledby="alert-dialog-slide-title"
                                                    aria-describedby="alert-dialog-slide-description"
                                                >
                                                    <DialogActions>
                                                        <Button onClick={handleClose} color="primary" autoFocus className={`${classes.closeButton}`}>
                                                            <ClearTwoToneIcon style={{ fontSize: 40 }} />
                                                        </Button>
                                                    </DialogActions>
                                                    <DialogContent style={{ marginTop: "9px" }}>
                                                        <CareerVacancie />
                                                    </DialogContent>
                                                </Dialog>
                                            </div>
                                        </div>
                                        {/* <Button size="small">Share Job <ShareIcon style={{ fontSize: 25, marginLeft: "5px", }} /></Button> */}
                                        <div className="btn_wrap">
                                            <span>Share <ShareIcon style={{ fontSize: 20 }} /></span>
                                            <div className="share-content">
                                                <a href="#"><i className="fa fa-facebook-f"></i></a>
                                                <a href="#"><i className="fa  fa-twitter"></i></a>
                                                <a href="#"><i className="fa fa-instagram"></i></a>
                                                <a href="#"><i className="fa fa-github"></i></a>
                                            </div>
                                        </div>
                                    </AccordionActions>
                                </Accordion>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 mb-4">
                                <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel10bh-content"
                                        id="panelbh-header"
                                    >
                                        <Typography className={classes.heading}>Lead, Product Growth (Worldwide Remote)</Typography>
                                        <div className="divider d-flex">
                                            <Typography className={classes.secondaryHeading}><span>Type: </span>Full Time</Typography>
                                            <Typography className={classes.secondaryHeading}><span>Min. Experience:</span> 2 Years</Typography>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography component={"span"}>
                                            <div className="text-holder">
                                                <span className="description">We are a diverse, fully-distributed team from around the world, working toward a shared vision to raise the standard of trust online.</span>
                                                <span className="description">Join our team as a remote Lead, Product Growth and help people reclaim their privacy online with DuckDuckGo.</span>
                                                <span className="description">As part of our growing team, you will collaborate with team members at all levels to identify and close major gaps in our products. Examples of projects you might work on include: experimentation with calls-to-action, establishing pathways for adoption of potential new products, and optimization of existing product content.</span>
                                                <div className="list">
                                                    <span className="list-title">What you will do:</span>
                                                    <ul>
                                                        <li>Lead projects from proposal through postmortem, assessing vague problems, proposing high impact solutions, and executing them against a set of success criteria.</li>
                                                        <li>Define and execute the strategy of our optimization work, including setting goals, drafting roadmaps, and driving the actual hypothesis generation and implementation of experiments in a very hands-on fashion.</li>
                                                        <li>Communicate the strategy and progress updates weekly to key stakeholders in the company, your team members and company-wide at our all-hands.</li>
                                                        <li>Deeply understand our most important product flows, marrying anonymous data with intuition to identify and prioritize the most impactful experiments that can improve product adoption.</li>
                                                        <li>Methodically execute experiments alongside cross-functional resources from design, front-end, back-end, mobile and more.</li>
                                                    </ul>
                                                </div>
                                                <div className="list">
                                                    <span className="list-title">What we are looking for:</span>
                                                    <ul>
                                                        <li>Lead projects from proposal through postmortem, assessing vague problems, proposing high impact solutions, and executing them against a set of success criteria.</li>
                                                        <li>Define and execute the strategy of our optimization work, including setting goals, drafting roadmaps, and driving the actual hypothesis generation and implementation of experiments in a very hands-on fashion.</li>
                                                        <li>Communicate the strategy and progress updates weekly to key stakeholders in the company, your team members and company-wide at our all-hands.</li>
                                                        <li>Deeply understand our most important product flows, marrying anonymous data with intuition to identify and prioritize the most impactful experiments that can improve product adoption.</li>
                                                        <li>Methodically execute experiments alongside cross-functional resources from design, front-end, back-end, mobile and more.</li>
                                                    </ul>
                                                </div>
                                                <div className="list">
                                                    <span className="list-title">Other things to know:</span>
                                                    <ul>
                                                        <li>Lead projects from proposal through postmortem, assessing vague problems, proposing high impact solutions, and executing them against a set of success criteria.</li>
                                                        <li>Define and execute the strategy of our optimization work, including setting goals, drafting roadmaps, and driving the actual hypothesis generation and implementation of experiments in a very hands-on fashion.</li>
                                                        <li>Communicate the strategy and progress updates weekly to key stakeholders in the company, your team members and company-wide at our all-hands.</li>
                                                        <li>Deeply understand our most important product flows, marrying anonymous data with intuition to identify and prioritize the most impactful experiments that can improve product adoption.</li>
                                                        <li>Methodically execute experiments alongside cross-functional resources from design, front-end, back-end, mobile and more.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Typography>
                                    </AccordionDetails>
                                    <AccordionActions>
                                        <div className="career-vacancies-modal container">
                                            <div className="career-modal">
                                                <Button variant="outlined" color="primary" onClick={handleClickOpen} className={`${classes.outlineButton}`}>
                                                    Apply Now <DescriptionOutlinedIcon style={{ fontSize: 24, marginLeft: "5px" }} />
                                                </Button>
                                                <Dialog
                                                    open={open}
                                                    fullScreen={fullScreen}
                                                    TransitionComponent={Transition}
                                                    keepMounted
                                                    onClose={handleClose}
                                                    aria-labelledby="alert-dialog-slide-title"
                                                    aria-describedby="alert-dialog-slide-description"
                                                >
                                                    <DialogActions>
                                                        <Button onClick={handleClose} color="primary" autoFocus className={`${classes.closeButton}`}>
                                                            <ClearTwoToneIcon style={{ fontSize: 40 }} />
                                                        </Button>
                                                    </DialogActions>
                                                    <DialogContent style={{ marginTop: "9px" }}>
                                                        <CareerVacancie />
                                                    </DialogContent>
                                                </Dialog>
                                            </div>
                                        </div>
                                        {/* <Button size="small">Share Job <ShareIcon style={{ fontSize: 25, marginLeft: "5px", }} /></Button> */}
                                        <div className="btn_wrap">
                                            <span>Share <ShareIcon style={{ fontSize: 20 }} /></span>
                                            <div className="share-content">
                                                <a href="#"><i className="fa fa-facebook-f"></i></a>
                                                <a href="#"><i className="fa  fa-twitter"></i></a>
                                                <a href="#"><i className="fa fa-instagram"></i></a>
                                                <a href="#"><i className="fa fa-github"></i></a>
                                            </div>
                                        </div>
                                    </AccordionActions>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CareerVacancie3;