import React, {useState} from "react";

// css
import "./index.css";

// react ui
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from "@material-ui/core/Button";
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';

// image
import Image from "images/bg-img06.jpg";

// react jss
import injectSheet from "react-jss";
import parse from "html-react-parser";

// style
const style = theme => ({
    millsberryClzModalBg: {},
    millsberryClzModalMain: {
        "& .content-holder": {},
    },
})

const MillsberryClzModal = ({classes, resource_path, item}) => {
    const [open, setOpen] = React.useState(true);
    const [scroll, setScroll] = React.useState('paper');

    const handleClickOpen = (scrollType) => () => {
        setOpen(true);
        setScroll(scrollType);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const {current: descriptionElement} = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    return (
        <section className={`${classes.millsberryClzModalBg}`}>
            <div className={`popup-modal ${classes.millsberryClzModalMain}`}>
                <div className="content-holder">
                    <div>
                        <Dialog
                            open={open}
                            onClose={handleClose}
                            scroll={scroll}
                            aria-labelledby="scroll-dialog-title"
                            aria-describedby="scroll-dialog-description"
                        >
                            <DialogActions>
                                <Button onClick={handleClose} color="primary">
                                    <ClearRoundedIcon/>
                                </Button>
                            </DialogActions>
                            <DialogContent dividers={scroll === 'paper'}>
                                <DialogContentText
                                    id="scroll-dialog-description"
                                    ref={descriptionElementRef}
                                    tabIndex={-1}
                                    // key={item.slug}
                                >
                                    {
                                        item.image &&
                                        <div className="img-holder">
                                            <div className="bg-stretch background-image cover"
                                                 style={{backgroundImage: `url(${resource_path + item.image})`}}></div>
                                        </div>
                                    }
                                    {
                                        item.description &&
                                        <Typography variant="h6" component="h2"
                                                    className={`text-center ${item.image ? "mt-2" : ""} `}>
                                            {parse(item.description)}
                                        </Typography>
                                    }
                                </DialogContentText>
                            </DialogContent>
                        </Dialog>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default injectSheet(style)(MillsberryClzModal);