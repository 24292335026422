import React, { Component } from 'react';
import { connect } from 'react-redux';

// import css
import './index.css';

//action import
import { sendContactMessage } from "./../../../actions/frontAction";

// import react js
import injectSheet from 'react-jss';

// import image
import Image from './../../../images/girl.png';

// component import
import { ToastContainer } from "react-toastify";
import toast from './../../../components/notification/Notify';

// theme 
const style = () => ({
    contactBg: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

    },
    contactSec: {
        background: "#FFF",
        boxShadow: "0 15px 60px rgb(232 232 232 / 50%)",
        padding: "0px",
        position: "relative",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        overflow: "hidden",
        borderRadius: "5px",
        "& .formbox": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "50%",
            padding: "50px 50px 0px 50px",
            "& form": {
                position: "trelative",
                width: "100%",
                "& h1": {
                    color: "#fff",
                    fontSize: "22px",
                    fontWeight: "800",
                    textTransform: "uppercase",
                    marginBottom: "40px",
                    padding: "0",
                    letterSpacing: "2px",
                },
                "& .inputbox": {
                    position: "relative",
                    marginBottom: "40px",
                    "& input, textarea": {
                        position: "relative",
                        background: "transparent",
                        outline: "none",
                        border: "none",
                        width: "100%",
                        color: "#000",
                        paddingBottom: "10px",
                        fontSize: "16px",
                        letterSpacing: "1px",
                        fontWeight: "300",
                        borderBottom: "2px solid rgba(255, 255, 255, 0.5)",
                    },
                    "& textarea": {
                        height: "100px",
                    },
                    "& span": {
                        position: "absolute",
                        left: "0",
                        fontSize: "16px",
                        display: "inline-block",
                        pointerEvents: "none",
                        letterSpacing: "1px",
                        color: "#000",
                        transition: "all 0.2s",
                        zIndex: "1",
                    },
                },
            },
            "& .upload-cv": {
                fontSize: "12px",
                color: "#dc3545",
            },
        },
        "& .imgBox": {
            // position: "relative",
            width: "50%",
            height: "100%",
            overflow: "hidden",
            "&::before": {
                content: "''",
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                background: "#002147",
                transformOrigin: "bottom",
                transform: "skewX(-25deg) translateX(55%)",
            },
            "& img": {
                position: "absolute",
                maxHeight: "95%",
                bottom: "0",
                // left: "45%",
            }
        },
        "@media(max-width: 600px)": {
            "& .formbox": {
                width: "100%",
                padding: "50px 0 0 0",
            },
        },
        "@media(min-width: 768px)": {
            "& .formbox": {
                width: "100%",
                padding: "50px 0 0 0",
            },
        },
        "@media(min-width: 992px)": {
            "& .formbox": {
                width: "50%",
                padding: "50px 0 0 0",
            },
        },
    },
    "@media(max-width: 600px)": {
        contactSec: {
            flexWrap: 'wrap',
            flexDirection: 'column',
        },
    },
    "@media(min-width: 768px)": {
        contactSec: {
            flexWrap: 'wrap',
            flexDirection: 'column',
        },
    },
    "@media(min-width: 992px)": {
        contactSec: {
            flexWrap: 'nowrap',
            flexDirection: 'row',
        },
    },
});

const DefaultForm = {
    name: "",
    email: "",
    phone: "",
    position: "",
    image: "",
};

class CareerVacancie extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                ...DefaultForm
            },
            error: {
                ...DefaultForm
            },
            validForm: false,
            isSubmitting: false
        };
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState((perviousState) => ({
            data: {
                ...perviousState.data,
                [name]: value
            },
        }), () => {
            this.validateErrors(name);
        });
    };

    validateErrors(fieldName) {
        let error;
        switch (fieldName) {
            case 'name':
                error = this.state.data[fieldName].trim().length ? '' : 'Name is Required';
                break;

            case 'email':
                if (this.state.data[fieldName].trim().length) {
                    error = this.state.data.email.includes('@') ? '' : 'Email is Invalid';
                } else {
                    error = 'Email is Required';
                }
                break;

            case 'phone':
                if (this.state.data[fieldName].trim().length) {
                    error = this.state.data.phone ? '' : 'Phone is Invalid';
                }
                else {
                    error = 'Phone Is Required';
                }
                break;
            case 'position':
                if (this.state.data[fieldName].trim().length) {
                    error = this.state.data.position ? '' : 'Position is Invalid';
                }
                else {
                    error = 'Position Is Required';
                }
                break;

            case 'image':
                error = this.state.data[fieldName].trim().length ? '' : 'File is Required';
                break;

            default:
                break;
        }
        this.setState((perviousState) => ({
            error: {
                ...perviousState.error,
                [fieldName]: error
            },
        }));
        return error.length == 0;
    }

    validateForm() {
        let isValid = true;
        Object.keys(this.state.data).map(key => {
            let isValidStatus = this.validateErrors(key);
            if (isValid) {
                isValid = isValidStatus;
            }
        });
        return isValid;
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if (this.validateForm()) {
            this.setState({
                isSubmitting: true
            });
            this.props.sendContactMessage(this.state.data).then(response => {
                if (response.success) {
                    toast.showSuccess(`Message Sent SuccessFully`);
                    this.setState({
                        data: DefaultForm
                    });
                } else {
                    toast.showError(response.message ? response.message : "Sent Faild");
                }
                this.setState({
                    isSubmitting: false
                });
            }).catch(err => {
                toast.showError("Something went wrong when sending Message!");
                this.setState({
                    isSubmitting: false
                });
            });
        }
    };

    render() {
        const { classes } = this.props;
        let button = this.state.isSubmitting ? <button className="btn-primary btn" type="submit" disabled={!this.state.validForm} calue="submit" onClick={this.toast}>Submitting...</button>
            : <button className="btn-primary btn" type="submit" calue="submit" onClick={this.toast}>Submit</button>
        return (
            <section id="contact-sec" className={`${classes.contactBg}`}>
                <div className={`contact-sec container-fluid ${classes.contactSec}`}>
                    <div className="formbox">
                        <form action="#" noValidate onSubmit={this.handleSubmit}>
                            <div className="inputbox">
                                <input value={this.state.data.name} onChange={this.handleChange} name="name" type="text" />
                                <span>Full Name</span>
                                <p style={{ color: "#dc3545", margin: "0", padding: "0" }}>
                                    {this.state.error.name}
                                </p>
                            </div>
                            <div className="inputbox">
                                <input value={this.state.data.email} onChange={this.handleChange} name="email" type="email" />
                                <span>Email</span>
                                <p style={{ color: "#dc3545", margin: "0", padding: "0" }}>
                                    {this.state.error.email}
                                </p>
                            </div>
                            <div className="inputbox">
                                <input value={this.state.data.phone} onChange={this.handleChange} name="phone" type="text" />
                                <span>Phone</span>
                                <p style={{ color: "#dc3545", margin: "0", padding: "0" }}>
                                    {this.state.error.phone}
                                </p>
                            </div>
                            <div className="inputbox">
                                <input value={this.state.data.position} onChange={this.handleChange} name="position" type="text" />
                                <span>Position</span>
                                <p style={{ color: "#dc3545", margin: "0", padding: "0" }}>
                                    {this.state.error.position}
                                </p>
                            </div>
                            <div className="inputbox">
                                <input id="file" type="file" label="Example file input" accept="application/pdf,application/vnd.ms-excel" value={this.state.data.image} onChange={this.handleChange} name="image" />
                                <span>Image</span>
                                <div className="upload-cv">(Upload Your CV)<sup>*</sup></div>
                                <p style={{ color: "#dc3545", margin: "0", padding: "0" }}>
                                    {this.state.error.image}
                                </p>
                            </div>
                            <div className="inputbox">
                                {button}
                            </div>
                        </form>
                    </div>
                    <div className="imgBox">
                        <img src={Image} alt="img" className="img-fluid" />
                    </div>
                    <ToastContainer />
                </div>
            </section>
        );
    }
};

function mapStateToProps(state) {
    return {
        template: state.layout.template.contact,
        map: state.layout.profile.data.map,
        bannerPath: state.layout.profile.resource_path,
        bannerImage: state.layout.profile.data.banner,
        breadCrumb: state.breadCrumb,
        activeBreadCrumb: state.activeBreadCrumb,
        profile: state.layout.profile,
        socialmedia: state.layout.socialmedia,
    };
}

export default connect(mapStateToProps, { sendContactMessage })(injectSheet(style)(CareerVacancie));