import React, {useState, useEffect, useLocalStorage} from "react";
import {Route, Switch} from "react-router-dom";
import {useHistory} from "react-router-dom";
import injectSheet from "react-jss";

// list of routes
import routes from "../utils/routes";

// components import
import ApiRoute from "../utils/ApiRoute";
import NoMatch from "../components/error/NoMatch";
import Blank from "../components/error/Blank";
import Messagner from "../components/social-plugin/Messanger";
import RenderBlocks from "../utils/RenderBlocks";
import GoogleAnalytics from "components/social-plugin/GoogleAnalytics";
import FacebookPixel from "components/social-plugin/FacebookPixel";
import DomainVerification from "components/social-plugin/DomainVerification";

// image
import Image from "images/walldorf.jpg";

// sxc homescreen
// const SxcWebsite = React.lazy(() => import("./../components/sxc-website/index"));

// dynamic global styling for websites
let dynamicStyles = null;
let styles = (theme) => {
    switch (theme.webId) {
        case 27:
            dynamicStyles = {
                ".navbar-expand-lg .navbar-nav .nav-link": {
                    paddingRight: ".7rem",
                    paddingLeft: ".7rem",
                },
            };
            break;

        case 49:
            dynamicStyles = {
                ".container-fluid": {
                    maxWidth: 1250,
                },
                ".navbar-expand-lg .navbar-nav .nav-link": {
                    paddingRight: ".3rem",
                    paddingLeft: ".3rem",
                },
            };
            break;

        case 37:
            dynamicStyles = {
                ".navbar-expand-lg .navbar-nav .nav-link": {
                    paddingRight: ".3rem",
                    paddingLeft: ".3rem",
                },
            };
            break;
        //
        // case 65:
        //     dynamicStyles = {
        //         "body": {
        //             backgroundImage: `url(${Image})`,
        //             height: "100%",
        //             width: "100%",
        //             backgroundSize: "cover",
        //             backgroundRepeat: "no-repeat",
        //         },
        //     };
        //     break;
        //
        case 67:
        case 72:
        case 73:
        case 74:
        case 75:
        case 77:
            dynamicStyles = {
                "body": {
                    fontFamily: "Arial, sans-serif",
                },
            };
            break;

        default:
            break;
    }
    return {
        "@global": {
            "#nprogress .bar": {
                background: theme.colors.primary,
            },
            ".btn-primary": {
                backgroundColor: theme.colors.primary,
                borderColor: theme.colors.primary,
                "&:hover,&:focus,&:active": {
                    backgroundColor: theme.colors.secondary + "!important",
                    borderColor: theme.colors.secondary + "!important",
                },
            },
            ".blank-page-content": {
                height: "447px",
            },
            ...dynamicStyles,
        },
    };
};

const MainAppNew = ({layout, theme}) => {
    let history = useHistory();
    const [homeScreenClosed, setHomeScreenClosed] = useState(
        !!sessionStorage.getItem("homeScreenClosed")
    );
    const onChange = (event) =>
        setHomeScreenClosed(event.target.homeScreenClosed);
    const homeScreen = null;
    // const homeScreen =
    // 	theme.webId === 55 ? (
    // 		<SxcWebsite
    // 			setHomeScreenClosed={setHomeScreenClosed}
    // 			onChange={onChange}
    // 		/>
    // 	) : null;

    useEffect(() => {
        if (homeScreen) {
            if (homeScreenClosed) {
                sessionStorage.setItem("homeScreenClosed", homeScreenClosed);
            } else {
                history.location.pathname !== "/" && history.push("/");
            }
        }
    }, [homeScreenClosed]);

    //   handle socialMedia data for rendering FacebookPixel, domain verification tag, google analytics
    const {Google, Messanger, Facebook} = layout.socialMedia.data || {};
    let pageId = Messanger?.link;
    const analyticsId = Google?.link;
    const fbItemLink = Facebook?.item_link ? Facebook.item_link.split(",") : [];
    const fbPixelId = fbItemLink[0];
    const domainVerfId = fbItemLink[1];

    return homeScreen && !homeScreenClosed ? (
        <React.Suspense fallback="">{homeScreen}</React.Suspense>
    ) : (
        <>
            <React.Suspense fallback="">
                <div className="HEADER-CONTAINER">
                    <RenderBlocks data={layout.template.header}/>
                </div>
            </React.Suspense>

            <div className="main-container" style={{minHeight: "40vh"}}>
                <Switch>
                    <Route path="/502" component={Blank}/>
                    <Route path="/404" component={NoMatch}/>
                    {routes.map((route, index) => {
                        return <ApiRoute key={index} {...route} />;
                    })}
                    <Route component={NoMatch}/>
                </Switch>
            </div>
            <React.Suspense fallback="">
                <div className="FOOTER-CONTAINER">
                    <RenderBlocks data={layout.template.footer}/>
                </div>
            </React.Suspense>

            {pageId ? <Messagner id={pageId}/> : null}
            {domainVerfId ? <DomainVerification id={domainVerfId}/> : null}
            {analyticsId ? <GoogleAnalytics id={analyticsId}/> : null}
            {fbPixelId ? <FacebookPixel id={fbPixelId}/> : null}
        </>
    );
};

export default injectSheet(styles)(MainAppNew);
