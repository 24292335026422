import React from "react";

// image
import Image from "images/bg-img06.jpg";
import Image2 from "images/curly-wave7.svg";

// react jss
import injectSheet from "react-jss";

// style
const style = theme => ({
    millsberryClzDetailPageBg: {
        height: "100vh",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        backgroundColor: "transparent !important",
        zIndex: 2,
        "&::before": {
            content: "''",
            position: "absolute",
            height: "264px",
            width: "100%",
            background: "linear-gradient(180deg,rgba(0,0,0,.65) 0,transparent)",
            top: "0",
        },
        "&::after": {
            content: "''",
            position: "absolute",
            height: "264px",
            width: "100%",
            background: "linear-gradient(to bottom,rgba(0,0,0,0) 0,rgba(0,0,0,.65) 100%)",
            bottom: "0",
        },
    },
    "@media(max-width: 600px)": {
        millsberryClzDetailPageBg: {
            clipPath: "unset",
        },

    },
    "@media(min-width: 768px)": {
        millsberryClzDetailPageBg: {
            clipPath: "unset",
        },

    },
    "@media(min-width: 992px)": {
        millsberryClzDetailPageBg: {
            clipPath: "ellipse(69% 100% at 50% 0%)",
        },
    },
    "@media(min-width: 1400px)": {
        millsberryClzDetailPageBg: {
            clipPath: "ellipse(69% 100% at 50% 0%)",
        },
    },
    "@media(min-width: 1600px)": {
        millsberryClzDetailPageBg: {
            clipPath: "ellipse(69% 100% at 50% 0%)",
        },
    },
    "@media(min-width: 1900px) and (min-width: 2550px)": {
        millsberryClzDetailPageBg: {
            clipPath: "ellipse(69% 100% at 50% 0%)",
        },
    },
})

const MillsberryClzDetailPage = ({classes, image}) => {
    return (
        <section className={`background-image cover ${classes.millsberryClzDetailPageBg}`}
                 style={{backgroundImage: `url(${image})`}}>
        </section>
    )
}

export default injectSheet(style)(MillsberryClzDetailPage);